/* stylelint-disable at-rule-empty-line-before */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './icons/byside.css';

@layer base {
    html,
    body {
        @apply font-default text-base;
    }
}
